import React from "react";
import "./style.css";
import logo from "../assets/logo.svg";
import MediaQuery from "react-responsive";
import banner from "../assets/banner1.png";
import composant1 from "../assets/portabilite.jpg"
import composant2 from "../assets/internet-mobile.jpg"
import composant3 from "../assets/mouzikti.jpg"
import composant5 from "../assets/Composant2.png"
import { Trans } from "react-i18next";
import footer from "../assets/copyright.svg"
import banner2 from "../assets/banner-photp-mobile.jpg";
import Slider from "react-slick";
import slide5W from "../assets/slide1-web.jpg";
import slide1W from "../assets/slide5-web.jpg";
import slide1M from "../assets/slide5-mobile.jpg";
import slide2W from "../assets/Slide2-web.jpg";
import slide3W from "../assets/Slide.jpg";
import slide4W from "../assets/Slide4-web.jpg"

import slide5M from "../assets/slide1-mobile.jpg";
import slide2M from "../assets/Slide2-mobile.jpg";
import slide3M from "../assets/Slide3-mobile.jpg";
import slide4M from "../assets/Slide4-mobile.jpg"

import a from "../assets/172.svg";
import b from "../assets/140.svg";
import c from "../assets/208.svg";
import d from "../assets/111.svg";
import f from "../assets/208.svg";
import imgwebVideo from "../assets/capture-video-web.jpg";
import imgmobileVideo from "../assets/capture-video-mobile.jpg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from 'react-player'
import CloseIcon from "@material-ui/icons/Close";
import Close from "../assets/close.png"







class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open:false

    }
  }
  handleOpen= () => {
    this.setState({
      open:true
    })
  }
  handleClose= () => {
    this.setState({
      open:false
    })
  }
  
  handleChange(){
    localStorage.setItem('i18nextLng', "ar");
    window.location.reload();
  }
  handleChange2(){
    localStorage.setItem('i18nextLng', "fr");
    window.location.reload();
  }
  
  

  render() {
    var setting = {
      dots: false,
      // overflow: "hidden",
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,


    }
    return (
      <div>
        {localStorage.getItem("i18nextLng") === "fr" ? (
          <div className="logo">
            <img src={logo} className="logoImg" />

            <button className="btnarabe" onClick={this.handleChange}>
              العربية{" "}
            </button>
          </div>
        ) : (
          <div className="logo">
            <img src={logo} className="logoImg" />

            <button className="btnFr" onClick={this.handleChange2}>
              Français{" "}
            </button>
          </div>
        )}
        <div></div>
        {/* <div className="divBanner"> */}
        <Slider {...setting} className="divSlider">
        <div>
            <MediaQuery minDeviceWidth={769}>
             <a href="https://jawhara.nety.tn/"><img src={slide1W} className="imgBanner" /></a> 
            </MediaQuery>

            <MediaQuery maxDeviceWidth={768}>
            <a href="https://jawhara.nety.tn/"> <img src={slide1M} className="imgBanner" /></a>
            </MediaQuery>
          </div>
          <div>
            <MediaQuery minDeviceWidth={769}>
              <img src={slide5W} className="imgBanner" />
            </MediaQuery>

            <MediaQuery maxDeviceWidth={768}>
              <img src={slide5M} className="imgBanner" />
            </MediaQuery>
          </div>
          
          <div>
            <MediaQuery minDeviceWidth={769}>
              <img src={slide4W} className="imgBanner" />
            </MediaQuery>

            <MediaQuery maxDeviceWidth={768}>
              <img src={slide4M} className="imgBanner" />
            </MediaQuery>
          </div>
          <div>
            <MediaQuery maxDeviceWidth={768}>
              <img src={slide2M} className="imgBanner" />
            </MediaQuery>
            <MediaQuery minDeviceWidth={769}>
              <img src={slide2W} className="imgBanner" />
            </MediaQuery>
          </div>

          <div>
            <MediaQuery maxDeviceWidth={768}>
              <img src={slide3M} className="imgBanner" />
            </MediaQuery>
            <MediaQuery minDeviceWidth={769}>
              <img src={slide3W} className="imgBanner" />
            </MediaQuery>
          </div>
        </Slider>
        <div className="divTitreVideo">
          <div className="divTitre">
            {localStorage.getItem("i18nextLng") === "fr" ? (
              <p className="titre">
                <Trans i18nKey="service" />
              </p>
            ) : (
              <p className="titreAr">
                <Trans i18nKey="service" />
              </p>
            )}

            {localStorage.getItem("i18nextLng") === "fr" ? (
              <p className="serv1">
                <Trans i18nKey="service2" />{" "}
                <span className="serv3">
                  <Trans i18nKey="service3" />{" "}
                </span>
                <Trans i18nKey="service4" />
                <span className="serv3">
                  <Trans i18nKey="service5" />
                </span>
                <Trans i18nKey="service6" />
              </p>
            ) : (
              <p className="serv1Mob">
                <Trans i18nKey="service2" />{" "}
                <span className="serv3Mob">
                  <Trans i18nKey="service3" />{" "}
                </span>
                <Trans i18nKey="service4" />
                <span className="serv3Mob">
                  <Trans i18nKey="service5" />
                </span>
                <Trans i18nKey="service6" />
              </p>
            )}
          </div>
          <div className="divVideo">
            <MediaQuery minDeviceWidth={769}>
              <img src={imgwebVideo} onClick={this.handleOpen} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={768}>
              <img src={imgmobileVideo} onClick={this.handleOpen} />
            </MediaQuery>
          </div>
        </div>

        {/* <div className="promo"> */}
        <div className="prompColumn">
          <div className="promoRow">
            <div className="imgMobile">
              <img src={composant1} className="img" />
            </div>
            {localStorage.getItem("i18nextLng") === "fr" ? (
              <div className="paragMobile">
                <p className="titreComposant">
                  <Trans i18nKey="porta" />
                </p>
                <p className="paragComposant">
                  <Trans i18nKey="portabilie-1" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie-2" />
                  </span>
                  <Trans i18nKey="portabilie" />
                </p>
                <div className="mobilegrtVtn">
                  <p className="parag2Composant">
                    <Trans i18nKey="composer" />
                  </p>
                  <div className="divMobileBtn">
                    {" "}
                    <img src={a} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="paragMobile">
                <p className="titreComposantAr">
                  <Trans i18nKey="porta" />
                </p>
                <p className="paragComposantMob">
                  <Trans i18nKey="portabilie-1" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie-2" />
                  </span>
                  <Trans i18nKey="portabilie-3" />
                  <span className="serv3">
                    <Trans i18nKey="portabilie-4" />
                  </span>
                  <Trans i18nKey="portabilie-5" />
                </p>
                <div className="mobilegrtVtn22">
                  <p className="parag2ComposantMob">
                    <Trans i18nKey="composer" />
                  </p>
                  <img src={a} className="imgmobiledes" />
                </div>
              </div>
            )}
          </div>
          <div className="promoRow">
            <div className="imgMobile">
              <img src={composant2} className="img" />
            </div>
            {localStorage.getItem("i18nextLng") === "fr" ? (
              <div className="paragMobile">
                <p className="titreComposant">
                  <Trans i18nKey="intrnetMobile" />
                </p>
                <p className="paragComposant">
                  <Trans i18nKey="portabilie2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-1" />
                  </span>
                  <Trans i18nKey="portabilie2-2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-3" />
                  </span>
                  <Trans i18nKey="portabilie2-4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-5" />
                  </span>
                </p>
                <div className="mobilegrtVtn">
                  <p className="parag2Composant">
                    <Trans i18nKey="composer" />
                  </p>
                  <div className="divMobileBtn">
                    <img src={b} className="imgmobiledes" />
                  </div>
                </div>
                <div className="divbtnIntMobile">
                  <p className="parag2Composant">
                    <Trans i18nKey="composer2" />
                  </p>
                  <div className="divMobileBtn">
                    <img src={f} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="paragMobile">
                <p className="titreComposantAr">
                  <Trans i18nKey="intrnetMobile" />
                </p>
                <p className="paragComposantMob">
                  <Trans i18nKey="portabilie2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-1" />
                  </span>
                  <Trans i18nKey="portabilie2-2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-3" />
                  </span>
                  <Trans i18nKey="portabilie2-4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-5" />
                  </span>
                  <Trans i18nKey="portabilie2-6" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-7" />
                  </span>
                  <Trans i18nKey="portabilie2-8" />
                  <Trans i18nKey="portabilie2-9" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-10" />
                  </span>
                  <Trans i18nKey="portabilie2-11" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie2-12" />
                  </span>
                </p>
                <div className="mobilegrtVtn22">
                  <p className="parag2ComposantMob">
                    <Trans i18nKey="composer" />
                  </p>
                  <div className="divMobileBtn">
                    <img src={b} className="imgmobiledes" />
                  </div>
                </div>
                <div className="divbtnIntMobile">
                  <p className="parag2ComposantMob">
                    <Trans i18nKey="composer2" />
                  </p>
                  <div className="divMobileBtn">
                    <img src={f} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="promoRow">
            <div className="imgMobile">
              <img src={composant3} className="img" />
            </div>
            {localStorage.getItem("i18nextLng") === "fr" ? (
              <div className="paragMobile">
                <p className="titreComposant">
                  <Trans i18nKey="mouzic" />
                </p>

                <p className="paragComposant">
                  <Trans i18nKey="portabilie3" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie3-1" />
                  </span>
                  <Trans i18nKey="portabilie3-2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie3-3" />
                  </span>
                  <Trans i18nKey="portabilie3-4" />
                </p>
                <div className="mobilegrtVtn">
                  <p className="parag2Composant">
                    <Trans i18nKey="composer" />
                  </p>
                  <div className="divMobileBtn">
                    <img src={c} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="paragMobile">
                <p className="titreComposantAr">
                  <Trans i18nKey="mouzic" />
                </p>

                <p className="paragComposantMob">
                  <Trans i18nKey="portabilie3" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie3-1" />
                  </span>
                  <Trans i18nKey="portabilie3-2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie3-3" />
                  </span>
                  <Trans i18nKey="portabilie3-4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie3-5" />
                  </span>
                  <Trans i18nKey="portabilie3-6" />
                </p>
                <div className="mobilegrtVtn22">
                  <p className="parag2ComposantMob">
                    <Trans i18nKey="composer" />
                  </p>
                  <div className="divMobileBtn">
                    <img src={c} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="promoRow">
            <div className="imgMobile">
              <img src={composant5} className="img" />
            </div>
            {localStorage.getItem("i18nextLng") === "fr" ? (
              <div className="paragMobile">
                <p className="titreComposant">
                  <Trans i18nKey="prog" />
                </p>
                <p className="paragComposant">
                  <Trans i18nKey="portabilie4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-1" />
                  </span>
                  <Trans i18nKey="portabilie4-2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-3" />
                  </span>
                  <Trans i18nKey="portabilie4-4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-5" />
                  </span>
                  <Trans i18nKey="portabilie4-6" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-7" />
                  </span>
                  <Trans i18nKey="portabilie4-8" />
                </p>
                <div className="mobilegrtVtn2">
                  <p className="parag2Composant">
                    <Trans i18nKey="composer" />
                  </p>

                  <div className="divMobileBtn">
                    <img src={d} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="paragMobile">
                <p className="titreComposantAr">
                  <Trans i18nKey="prog" />
                </p>
                <p className="paragComposantMob">
                  <Trans i18nKey="portabilie4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-1" />
                  </span>
                  <Trans i18nKey="portabilie4-2" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-3" />
                  </span>
                  <Trans i18nKey="portabilie4-4" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-5" />
                  </span>
                  <Trans i18nKey="portabilie4-6" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-8" />
                  </span>
                  <Trans i18nKey="portabilie4-9" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-10" />
                  </span>
                  <Trans i18nKey="portabilie4-11" />
                  <span className="serv3-1">
                    <Trans i18nKey="portabilie4-12" />
                  </span>
                </p>
                <div className="mobilegrtVtn2">
                  <p className="parag2Composant">
                    <Trans i18nKey="composer" />
                  </p>

                  <div className="divMobileBtn">
                    <img src={d} className="imgmobiledes" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <img src={footer} />
          <p className="titreFooter">All Rights reserved to Jawhara Mobile</p>

          {this.state.open === true && (
            <div>
              <Dialog
                open={this.state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div>
                  <CloseIcon
                    style={{
                      float: "right",
                      color: "white",
                      cursor: "pointer",
                      padding: "2%",
                    }}
                    onClick={this.handleClose}
                  />
                </div>

                <DialogContent>
                  <iframe
                    width="560"
                    height="315"
                    className="withIframe"
                    src="https://www.youtube.com/embed/3n3mMwgLg3Y?autoplay=1"
                    frameborder="0"
                    allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                    autoplay="true"
                  ></iframe>
                  {/* <ReactPlayer url='https://www.youtube.com/watch?v=3n3mMwgLg3Y' playing /> */}
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      </div>
    )
  }
}



export default Home;
