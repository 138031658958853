import React, { Component } from "react";
import logo from './logo.svg';
import { translate } from "react-i18next";

import './App.css';
import { Route, Switch } from "react-router-dom";
import Home from "./pages/home";

class App extends Component {
  render() {
    if (
      localStorage.getItem("i18nextLng") !== "fr" &&
      localStorage.getItem("i18nextLng") !== "ar"
    ) {
      localStorage.setItem("i18nextLng", "fr");
    }
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route  path="/ar" component={HomeAr} /> */}

      </Switch>
    </div>
  );
}
}
export default translate("translations")(App);
